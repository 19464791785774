<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="col-md-12" style="background:white">
        <table class="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th colspan="2">
                <button
                  class="btn btn-info"
                  style="float: right"
                  @click="showModal"
                >
                  <i class="fa fa-plus"></i> Add Role
                </button>
              </th>
            </tr>

            <tr>
              <th>SN</th>
              <th>Name</th>
              <th style="width:20%">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loadingData && getRoleData.data.length > 0">
            <tr v-for="(item, index) in getRoleData.data" :key="index">
              <td>{{ ++index }}</td>
              <td>{{ item.title }}</td>

              <td style="width:100px">
                <button
                  class="btn btn-sm btn-primary mr-3 mt-2"
                  style="cursor: pointer;"
                  @click="gotoRolePermissions(item.id)"
                >
                  Assign permissions
                </button>

                <button
                  @click="edit(item)"
                  class="btn btn-sm btn-success mr-3 mt-2"
                  style="cursor: pointer;"
                >
                  Edit
                </button>
                <button
                  @click="deleteItem(item.id)"
                  class="btn btn-sm btn-danger mr-3 mt-2"
                  style="cursor: pointer;"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>

          <tbody v-if="!loadingData && getRoleData.length < 1">
            <tr>
              <td colspan="8" class="text-center">No record(s) found</td>
            </tr>
          </tbody>

          <tbody v-if="loadingData">
            <tr>
              <th class="text-center" colspan="10">
                <vDataLoader></vDataLoader>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <role
        v-if="ModalShow"
        v-on:closemodal="closeModal"
        :show-modal="ModalShow"
        :role="roleData"
        :is_edit="is_edit"
      ></role>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_ROLES,
  CREATE_ROLE,
  DELETE_ROLE
} from "@/core/services/store/actions.type";
import role from "@/pages/backend/master/roles/role";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentUser", "getRoleData"])
  },
  mounted() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_ROLES);
  },
  components: {
    role
  },
  methods: {
    showModal() {
      this.roleData = {
        id: 0,
        title: ""
      };
      this.ModalShow = true;
      this.is_edit = false;
    },
    closeModal() {
      this.ModalShow = false;
      this.$store.dispatch(FETCH_ROLES);
    },
    edit(item) {
      this.ModalShow = true;
      this.is_edit = true;
      this.roleData = {
        ...item
      };
    },
    update(item) {
      let data = { ...item };
      data.image = "none";
      this.$store
        .dispatch(CREATE_ROLE, data)
        .then(() => {
          this.$toastr.s("Role Successfully Updated ");
          this.$store.dispatch(FETCH_ROLES);
        })
        .catch(err => {
          this.errors = err;
        });
    },
    deleteItem(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store
            .dispatch(DELETE_ROLE, id)
            .then(() => {
              this.$toastr.s("Role Deleted ");
              this.$store.dispatch(FETCH_ROLES);
            })
            .catch(err => {
              this.errors = err;
            });
        }
      });
    },
    gotoRolePermissions(id) {
      this.$router.push({
        name: "client.role.permissions",
        params: { id: id }
      });
    }
  },
  data() {
    return {
      loadingData: false,
      ModalShow: false,
      roles: [],
      columns: [
        {
          name: "Role name",
          width: "",
          label: "name ",
          class: ""
        }
      ],
      is_edit: false,
      roleData: {
        id: 0,
        title: ""
      },
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Role List",
          route: ""
        }
      ]
    };
  }
};
</script>
